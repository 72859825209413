import { t } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import { ChainId, NVLS_ADDRESS } from '@nevellusdex/core-sdk'
import { Feature } from 'app/enums'
import { featureEnabled } from 'app/functions'
import { useActiveWeb3React } from 'app/services/web3'
import { ReactNode, useMemo } from 'react'

export interface MenuItemLeaf {
  key: string
  title: string
  link: string
  icon?: ReactNode
}

export interface MenuItemNode {
  key: string
  title: string
  items: MenuItemLeaf[]
  icon?: ReactNode
}

export type MenuItem = MenuItemLeaf | MenuItemNode
export type Menu = MenuItem[]

type UseMenu = () => Menu
const useMenu: UseMenu = () => {
  const { i18n } = useLingui()
  const { chainId, account } = useActiveWeb3React()

  return useMemo(() => {
    if (!chainId) return []

    const menu: Menu = []

    // Menu with options of trade types
    // let tradeMenu: MenuItem = {
    //   key: 'trade',
    //   title: i18n._(t`Trading`),
    //   items: [
    //     {
    //       key: 'swap',
    //       title: i18n._(t`Swap`),
    //       link: '/swap',
    //     },
    //     {
    //       key: 'limit-orders',
    //       title: i18n._(t`Limit Orders`),
    //       link: '/limit-order',
    //     },
    //   ],
    // }
    // if (featureEnabled(Feature.LIMIT_ORDERS, chainId)) {
    //   menu.push(tradeMenu)
    // } else if (featureEnabled(Feature.AMM, chainId)) {
    //   menu.push({
    //     key: 'trade',
    //     title: i18n._(t`Swap`),
    //     link: '/swap',
    //   })
    // }

    // Only one link for trade
    if (featureEnabled(Feature.AMM, chainId)) {
      const trading = {
        key: 'trade',
        title: i18n._(t`Trading`),
        link: '/swap',
      }
      menu.push(trading)
    }
    if (featureEnabled(Feature.AMM, chainId)) {
      const trading = {
        key: 'pool',
        title: i18n._(t`Pools`),
        link: '/pool',
      }
      menu.push(trading)
    }

    // const liquidity = [
    //   {
    //     key: 'pool',
    //     title: i18n._(t`Pool`),
    //     link: '/pool',
    //   },
    //   {
    //     key: 'migrate-liquidty',
    //     title: i18n._(t`Migrate`),
    //     link: '/migrate',
    //     disabled: !featureEnabled(Feature.MIGRATE, chainId),
    //   },
    // ]
    // if (featureEnabled(Feature.AMM, chainId)) {
    //   menu.push({
    //     key: 'Liquidity',
    //     title: i18n._(t`Liquidity`),
    //     items: liquidity.filter((item) => !item?.disabled),
    //   })
    // }

    if (featureEnabled(Feature.LIQUIDITY_MINING, chainId)) {
      const farmItems = {
        key: 'farm',
        title: i18n._(t`Enceladus`),
        link: '/farm',
      }
      menu.push(farmItems)
    }

    const analytics: MenuItem = {
      key: 'analytics',
      title: i18n._(t`Analytics`),
      items: [
        {
          key: 'dashboard',
          title: i18n._(t`Dashboard`),
          link: '/analytics',
        },
        {
          key: 'enceladus',
          title: i18n._(t`Enceladus`),
          link: '/analytics/farms',
        },
      ],
    }
    if (featureEnabled(Feature.ANALYTICS, chainId)) {
      menu.push(analytics)
    }

    if (featureEnabled(Feature.VAULT, chainId)) {
      analytics.items.push({
        key: 'nevellusvault',
        title: 'NevellusVault',
        link: `/analytics/vault`,
      })
    }

    if (account && chainId !== ChainId.ARBITRUM_NOVA) {
      analytics.items.push({
        key: 'account',
        title: 'Account',
        link: `/account?account=${account}`,
      })

      if (featureEnabled(Feature.SUBGRAPH, chainId)) {
        analytics.items.push({
          key: 'liquidity',
          title: 'Liquidity',
          link: `/account/liquidity?account=${account}`,
        })
      }
    }

    return menu.filter((el) => Object.keys(el).length > 0)
  }, [account, chainId, i18n])
}

export default useMenu
