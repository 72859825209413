import { ChainId } from '@nevellusdex/core-sdk'
import { Feature } from 'app/enums'

type FeatureMap = { readonly [chainId in ChainId]?: Feature[] }

const features: FeatureMap = {
  [ChainId.ETHEREUM]: [
    Feature.AMM,
    Feature.LIQUIDITY_MINING,
    Feature.ANALYTICS,
    Feature.STAKING,
    // Feature.LIMIT_ORDERS,
    Feature.NEVELLUSGUARD,
    Feature.SUBGRAPH,
  ],
  [ChainId.GÖRLI]: [
    Feature.AMM,
    Feature.LIQUIDITY_MINING,
    Feature.ANALYTICS,
    Feature.STAKING,
    Feature.SUBGRAPH,
  ],
  // [ChainId.KOVAN]: [
  //   Feature.AMM,
  //   Feature.LIQUIDITY_MINING,
  // ],
  // [ChainId.BSC]: [Feature.AMM, Feature.ANALYTICS, Feature.SUBGRAPH],
  // [ChainId.BSC_TESTNET]: [Feature.AMM],
  // [ChainId.FANTOM]: [
  //   Feature.AMM,
  //   Feature.ANALYTICS,
  //   Feature.LIMIT_ORDERS,
  //   Feature.LIQUIDITY_MINING,
  //   Feature.SUBGRAPH,
  // ],
  // [ChainId.FANTOM_TESTNET]: [Feature.AMM],
  // [ChainId.MATIC]: [
  //   Feature.AMM,
  //   Feature.LIQUIDITY_MINING,
  //   Feature.ANALYTICS,
  //   Feature.LIMIT_ORDERS,
  //   Feature.SUBGRAPH,
  // ],
  [ChainId.MATIC_TESTNET]: [
    Feature.AMM,
    Feature.LIQUIDITY_MINING,
    Feature.STAKING,
    Feature.SUBGRAPH,
  ],
  // [ChainId.HARMONY]: [Feature.AMM, Feature.LIQUIDITY_MINING, Feature.ANALYTICS, Feature.SUBGRAPH],
  // [ChainId.HARMONY_TESTNET]: [Feature.AMM],
  // [ChainId.AVALANCHE]: [
  //   Feature.AMM,
  //   Feature.LIMIT_ORDERS,
  //   Feature.ANALYTICS,
  //   Feature.SUBGRAPH,
  // ],
  // [ChainId.AVALANCHE_TESTNET]: [Feature.AMM],
  // [ChainId.OKEX]: [Feature.AMM],
  // [ChainId.OKEX_TESTNET]: [Feature.AMM],
  // [ChainId.XDAI]: [
  //   Feature.AMM,
  //   Feature.LIQUIDITY_MINING,
  //   Feature.ANALYTICS,
  //   Feature.SUBGRAPH,
  // ],
  // [ChainId.MOONRIVER]: [Feature.AMM, Feature.LIQUIDITY_MINING, Feature.ANALYTICS, Feature.SUBGRAPH],
  // [ChainId.CELO]: [Feature.AMM, Feature.LIQUIDITY_MINING, Feature.ANALYTICS, Feature.SUBGRAPH],
  // [ChainId.ARBITRUM]: [
  //   Feature.AMM,
  //   Feature.LIQUIDITY_MINING,
  //   Feature.ANALYTICS,
  //   Feature.SUBGRAPH,
  // ],
  // [ChainId.FUSE]: [Feature.AMM, Feature.LIQUIDITY_MINING, Feature.ANALYTICS, Feature.SUBGRAPH],
  // [ChainId.MOONBEAM]: [Feature.AMM, Feature.LIQUIDITY_MINING, Feature.SUBGRAPH],
  // [ChainId.OPTIMISM]: [ Feature.SUBGRAPH],
  // [ChainId.KAVA]: [Feature.LIQUIDITY_MINING, Feature.SUBGRAPH],
  // [ChainId.METIS]: [Feature.LIQUIDITY_MINING, Feature.SUBGRAPH],
  // [ChainId.ARBITRUM_NOVA]: [Feature.AMM],
  // [ChainId.BOBA_AVAX]: [Feature.AMM],
}

export default features
