import { Contract } from '@ethersproject/contracts'
import {
  BAR_ADDRESS,
  // BORING_HELPER_ADDRESS,
  CHAIN_KEY,
  ChainId,
  CHAINLINK_ORACLE_ADDRESS,
  ENS_REGISTRAR_ADDRESS,
  FACTORY_ADDRESS,
  // MAKER_ADDRESS,
  ENCELADUS_ADDRESS,
  ENCELADUS_V2_ADDRESS,
  MERKLE_DISTRIBUTOR_ADDRESS,
  DIONE_ADDRESS,
  MULTICALL2_ADDRESS,
  ROUTER_ADDRESS,
  NVLS_ADDRESS,
  TIMELOCK_ADDRESS,
  WNATIVE_ADDRESS,
  BENTOBOX_ADDRESS,
} from '@nevellusdex/core-sdk'
import TRIDENT from '@nevellusdex/trident/exports/all.json'
import { LIMIT_ORDER_HELPER_ADDRESS, STOP_LIMIT_ORDER_ADDRESS } from '@nevellusdex/limit-order-sdk'
import {
  ARGENT_WALLET_DETECTOR_ABI,
  ARGENT_WALLET_DETECTOR_MAINNET_ADDRESS,
} from 'app/constants/abis/argent-wallet-detector'
import BAR_ABI from 'app/constants/abis/bar.json'
import BENTOBOX_ABI from 'app/constants/abis/bentobox.json'
import BORING_HELPER_ABI from 'app/constants/abis/boring-helper.json'
import CHAINLINK_ORACLE_ABI from 'app/constants/abis/chainlink-oracle.json'
import CLONE_REWARDER_ABI from 'app/constants/abis/clone-rewarder.json'
import COMPLEX_REWARDER_ABI from 'app/constants/abis/complex-rewarder.json'
import DASHBOARD_ABI from 'app/constants/abis/dashboard.json'
import EIP_2612_ABI from 'app/constants/abis/eip-2612.json'
import ENCELADUS_ABI from 'app/constants/abis/enceladus.json'
import ENCELADUS_V2_ABI from 'app/constants/abis/enceladus-v2.json'
import ENS_PUBLIC_RESOLVER_ABI from 'app/constants/abis/ens-public-resolver.json'
import ENS_ABI from 'app/constants/abis/ens-registrar.json'
import { ERC20_BYTES32_ABI } from 'app/constants/abis/erc20'
import ERC20_ABI from 'app/constants/abis/erc20.json'
import FACTORY_ABI from 'app/constants/abis/factory.json'
import INARI_ABI from 'app/constants/abis/inari.json'
import MULTICALL_ABI from 'app/constants/abis/interface-multicall.json'
import KASHI_REPOSITORY_ABI from 'app/constants/abis/kashi-repository.json'
import LIMIT_ORDER_ABI from 'app/constants/abis/limit-order.json'
import LIMIT_ORDER_HELPER_ABI from 'app/constants/abis/limit-order-helper.json'
// import MAKER_ABI from 'app/constants/abis/maker.json'
import MEOWSHI_ABI from 'app/constants/abis/meowshi.json'
import MERKLE_DISTRIBUTOR_ABI from 'app/constants/abis/merkle-distributor.json'
import DIONE_ABI from 'app/constants/abis/dione.json'
import MISO_HELPER_ABI from 'app/constants/abis/miso-helper.json'
import MULTICALL2_ABI from 'app/constants/abis/multicall2.json'
import NVLS_ABI from 'app/constants/abis/nvls.json'
import ROUTER_ABI from 'app/constants/abis/router.json'
import NEVELLUSMIGRATOR_ABI from 'app/constants/abis/nevellusmigrator.json'
import TIMELOCK_ABI from 'app/constants/abis/timelock.json'
import NEVELLUS_FACTORY_ABI from 'app/constants/abis/NevellusSwapFactory.json'
import INevellusSwapPairABI from 'app/constants/abis/NevellusSwapPair.json'
import WETH9_ABI from 'app/constants/abis/weth.json'
import ZENKO_ABI from 'app/constants/abis/zenko.json'
import LPToken from 'app/features/migration/LPToken'
import { getContract } from 'app/functions'
import { useActiveWeb3React } from 'app/services/web3'
import { useMemo } from 'react'

const UNI_FACTORY_ADDRESS = '0x5C69bEe701ef814a2B6a3EDD4B1652CB9cc5aA6f'

export function useEIP2612Contract(tokenAddress?: string): Contract | null {
  return useContract(tokenAddress, EIP_2612_ABI, false)
}

// returns null on errors
export function useContract<T extends Contract = Contract>(
  addressOrAddressMap: string | { [chainId: number]: string } | undefined,
  ABI: any,
  withSignerIfPossible = true
): T | null {
  const { library, account, chainId } = useActiveWeb3React()

  return useMemo(() => {
    if (!addressOrAddressMap || !ABI || !library || !chainId) return null
    let address: string | undefined
    if (typeof addressOrAddressMap === 'string') address = addressOrAddressMap
    else address = addressOrAddressMap[chainId]
    if (!address) return null
    try {
      return getContract(address, ABI, library, withSignerIfPossible && account ? account : undefined)
    } catch (error) {
      console.error('Failed to get contract', error)
      return null
    }
  }, [addressOrAddressMap, ABI, library, chainId, withSignerIfPossible, account]) as T
}

export function useTokenContract(tokenAddress?: string, withSignerIfPossible?: boolean): Contract | null {
  return useContract(tokenAddress, ERC20_ABI, withSignerIfPossible)
}

export function useWETH9Contract(withSignerIfPossible?: boolean): Contract | null {
  const { chainId } = useActiveWeb3React()
  return useContract(chainId ? WNATIVE_ADDRESS[chainId] : undefined, WETH9_ABI, withSignerIfPossible)
}

export function useArgentWalletDetectorContract(): Contract | null {
  const { chainId } = useActiveWeb3React()
  return useContract(
    chainId === ChainId.ETHEREUM ? ARGENT_WALLET_DETECTOR_MAINNET_ADDRESS : undefined,
    ARGENT_WALLET_DETECTOR_ABI,
    false
  )
}

export function useENSRegistrarContract(withSignerIfPossible?: boolean): Contract | null {
  const { chainId } = useActiveWeb3React()
  return useContract(chainId ? ENS_REGISTRAR_ADDRESS[chainId] : undefined, ENS_ABI, withSignerIfPossible)
}

export function useENSResolverContract(address: string | undefined, withSignerIfPossible?: boolean): Contract | null {
  return useContract(address, ENS_PUBLIC_RESOLVER_ABI, withSignerIfPossible)
}

export function useBytes32TokenContract(tokenAddress?: string, withSignerIfPossible?: boolean): Contract | null {
  return useContract(tokenAddress, ERC20_BYTES32_ABI, withSignerIfPossible)
}

export function usePairContract(pairAddress?: string, withSignerIfPossible?: boolean): Contract | null {
  return useContract(pairAddress, INevellusSwapPairABI, withSignerIfPossible)
}

export function useMerkleDistributorContract(): Contract | null {
  const { chainId } = useActiveWeb3React()
  return useContract(chainId ? MERKLE_DISTRIBUTOR_ADDRESS[chainId] : undefined, MERKLE_DISTRIBUTOR_ABI, true)
}

export function useProtocolMerkleDistributorContract(): Contract | null {
  const { chainId } = useActiveWeb3React()
  return useContract(chainId ? '0x1026cbed7b7E851426b959BC69dcC1bf5876512d' : undefined, MERKLE_DISTRIBUTOR_ABI, true)
}

export function useKashiRepositoryContract(): Contract | null {
  const { chainId } = useActiveWeb3React()
  return useContract(chainId ? '0x400AFAbFB249210E08A8dfC429FfE20d32245f57' : undefined, KASHI_REPOSITORY_ABI, false)
}

export function useBoringHelperContract(): Contract | null {
  const { chainId } = useActiveWeb3React()
  // TODO ramin update in sdk
  return useContract(chainId ? '0x5bd6e4eFA335192FDA5D6B42a344ccA3d45894B8' : undefined, BORING_HELPER_ABI, false)
}

export function useMulticall2Contract() {
  const { chainId } = useActiveWeb3React()
  return useContract(chainId ? MULTICALL2_ADDRESS[chainId] : undefined, MULTICALL2_ABI, false)
}

const MULTICALL_ADDRESS = {
  [ChainId.ETHEREUM]: '0x1F98415757620B543A52E61c46B32eB19261F984',
  [ChainId.GÖRLI]: '0x1F98415757620B543A52E61c46B32eB19261F984',
  [ChainId.MATIC]: '0x1F98415757620B543A52E61c46B32eB19261F984',
  [ChainId.MATIC_TESTNET]: '0xdDCbf776dF3dE60163066A5ddDF2277cB445E0F3',
  [ChainId.CELO]: '0x3d0B3b816DC1e0825808F27510eF7Aa5E3136D75',
  [ChainId.XDAI]: '0x2B75358D07417D4e895c952Ca84A44E63AEBE3Dd',
  [ChainId.FUSE]: '0x393B6DC9B00E18314888678721eC0e923FC5f49D',
}

export function useInterfaceMulticall(): Contract | null | undefined {
  return useContract(MULTICALL_ADDRESS, MULTICALL_ABI, false)
}

export function useNvlsContract(withSignerIfPossible = true): Contract | null {
  const { chainId } = useActiveWeb3React()
  return useContract(chainId ? NVLS_ADDRESS[chainId] : undefined, NVLS_ABI, withSignerIfPossible)
}

export function useEnceladusContract(withSignerIfPossible?: boolean): Contract | null {
  const { chainId } = useActiveWeb3React()
  return useContract(chainId ? ENCELADUS_ADDRESS[chainId] : undefined, ENCELADUS_ABI, withSignerIfPossible)
}

export function useEnceladusV2Contract(withSignerIfPossible?: boolean): Contract | null {
  const { chainId } = useActiveWeb3React()
  return useContract(chainId ? ENCELADUS_V2_ADDRESS[chainId] : undefined, ENCELADUS_V2_ABI, withSignerIfPossible)
}
export function useDioneContract(withSignerIfPossible?: boolean): Contract | null {
  const { chainId } = useActiveWeb3React()
  return useContract(chainId ? DIONE_ADDRESS[chainId] : undefined, DIONE_ABI, withSignerIfPossible)
}

export function useFactoryContract(): Contract | null {
  const { chainId } = useActiveWeb3React()
  return useContract(chainId ? FACTORY_ADDRESS[chainId] : undefined, FACTORY_ABI, false)
}

export function useRouterContract(withSignerIfPossible?: boolean): Contract | null {
  const { chainId } = useActiveWeb3React()
  // @ts-ignore TYPE NEEDS FIXING
  return useContract(ROUTER_ADDRESS[chainId], ROUTER_ABI, withSignerIfPossible)
}

// export function useNvlsBarContract(withSignerIfPossible?: boolean): Contract | null {
//   const { chainId } = useActiveWeb3React()
//   return useContract(chainId ? BAR_ADDRESS[chainId] : undefined, BAR_ABI, withSignerIfPossible)
// }

// export function useMakerContract(): Contract | null {
//   const { chainId } = useActiveWeb3React()
//   return useContract(chainId ? MAKER_ADDRESS[chainId] : undefined, MAKER_ABI, false)
// }

export function useTimelockContract(): Contract | null {
  const { chainId } = useActiveWeb3React()
  return useContract(chainId ? TIMELOCK_ADDRESS[chainId] : undefined, TIMELOCK_ABI, false)
}

export function useBentoBoxContract(withSignerIfPossible?: boolean): Contract | null {
  const { chainId } = useActiveWeb3React()
  return useContract(chainId ? BENTOBOX_ADDRESS[chainId] : undefined, BENTOBOX_ABI, withSignerIfPossible)
}

export function useChainlinkOracle(): Contract | null {
  const { chainId } = useActiveWeb3React()
  return useContract(chainId ? CHAINLINK_ORACLE_ADDRESS[chainId] : undefined, CHAINLINK_ORACLE_ABI, false)
}

export function useUniV2FactoryContract(): Contract | null {
  return useContract(UNI_FACTORY_ADDRESS, NEVELLUS_FACTORY_ABI, false)
}

export function useNvlsBarContract(withSignerIfPossible?: boolean): Contract | null {
  const { chainId } = useActiveWeb3React()
  return useContract(chainId ? BAR_ADDRESS[chainId] : undefined, BAR_ABI, withSignerIfPossible)
}

// @ts-ignore TYPE NEEDS FIXING
export function useComplexRewarderContract(address, withSignerIfPossible?: boolean): Contract | null {
  return useContract(address, COMPLEX_REWARDER_ABI, withSignerIfPossible)
}

// @ts-ignore TYPE NEEDS FIXING
export function useCloneRewarderContract(address, withSignerIfPossibe?: boolean): Contract | null {
  return useContract(address, CLONE_REWARDER_ABI, withSignerIfPossibe)
}

export function useMeowshiContract(withSignerIfPossible?: boolean): Contract | null {
  return useContract('0x650F44eD6F1FE0E1417cb4b3115d52494B4D9b6D', MEOWSHI_ABI, withSignerIfPossible)
}

export function useLimitOrderContract(withSignerIfPossibe?: boolean): Contract | null {
  const { chainId } = useActiveWeb3React()
  return useContract(chainId ? STOP_LIMIT_ORDER_ADDRESS[chainId] : undefined, LIMIT_ORDER_ABI, withSignerIfPossibe)
}

export function useLimitOrderHelperContract(withSignerIfPossible?: boolean): Contract | null {
  const { chainId } = useActiveWeb3React()
  return useContract(
    chainId ? LIMIT_ORDER_HELPER_ADDRESS[chainId] : undefined,
    LIMIT_ORDER_HELPER_ABI,
    withSignerIfPossible
  )
}

export function useInariContract(withSignerIfPossible?: boolean): Contract | null {
  return useContract('0x195E8262AA81Ba560478EC6Ca4dA73745547073f', INARI_ABI, withSignerIfPossible)
}

export function useZenkoContract(withSignerIfPossible?: boolean): Contract | null {
  return useContract('0xa8f676c49f91655ab3b7c3ea2b73bb3088b2bc1f', ZENKO_ABI, withSignerIfPossible)
}

export function useTridentMigrationContract() {
  const { chainId } = useActiveWeb3React()
  return useContract(
    chainId ? (TRIDENT as any)[chainId][0].contracts.TridentNvlsRollCP.address : undefined,
    chainId ? (TRIDENT as any)[chainId][0].contracts.TridentNvlsRollCP.abi : undefined
  )
}

export function useTridentRouterContract(withSignerIfPossible?: boolean): Contract | null {
  const { chainId } = useActiveWeb3React()
  // @ts-ignore TYPE NEEDS FIXING
  const router = TRIDENT[chainId]?.[0]?.contracts.TridentRouter
  return useContract(router?.address, router?.abi, withSignerIfPossible)
}

export function useMasterDeployerContract(withSignerIfPossible?: boolean): Contract | null {
  const { chainId } = useActiveWeb3React()
  // @ts-ignore TYPE NEEDS FIXING
  const masterDeployer = TRIDENT[chainId]?.[0]?.contracts.MasterDeployer
  return useContract(masterDeployer?.address, masterDeployer?.abi, withSignerIfPossible)
}

export function useConstantProductPoolFactory(withSignerIfPossible?: boolean): Contract | null {
  const { chainId } = useActiveWeb3React()
  // @ts-ignore TYPE NEEDS FIXING
  const factory = TRIDENT[chainId]?.[0]?.contracts.ConstantProductPoolFactory
  return useContract(factory?.address, factory?.abi, withSignerIfPossible)
}

export function useStablePoolFactory(withSignerIfPossible?: boolean): Contract | null {
  const { chainId } = useActiveWeb3React()
  // @ts-ignore TYPE NEEDS FIXING
  const factory = TRIDENT[chainId]?.[0]?.contracts.HybridPoolFactory
  return useContract(factory?.address, factory?.abi, withSignerIfPossible)
}

export function useMisoHelperContract(withSignerIfPossible = true): Contract | null {
  const { chainId } = useActiveWeb3React()
  // @ts-ignore TYPE NEEDS FIXING
  const factory = MISO[chainId]?.[CHAIN_KEY[chainId]]?.contracts.MISOHelper
  return useContract(factory?.address, MISO_HELPER_ABI, withSignerIfPossible)
}

export function useNevellusMigratorContract(dex: LPToken['dex']): Contract | null {
  const { chainId } = useActiveWeb3React()
  let address: string | undefined
  if (chainId) {
    switch (chainId) {
      case ChainId.ETHEREUM:
        if (dex === 'uniswap_v2') {
          address = '0x16E58463eb9792Bc236d8860F5BC69A81E26E32B'
        }
        break
        case ChainId.GÖRLI:
        if (dex === 'uniswap_v2') {
          address = '0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D'
        }
        break
        case ChainId.MATIC:
        if (dex === 'quickswap') {
          address = '0x0053957E18A0994D3526Cf879A4cA7Be88e8936A'
        }
        break
      // Spookyswap
      // 0xFB232C6D1E3ad48fEdF8A29c7dEf7A33ce43E56a
    }
  }
  return useContract(address, NEVELLUSMIGRATOR_ABI, true)
}

export function useDashboardContract(): Contract | null {
  const { chainId } = useActiveWeb3React()
  let address: string | undefined
  if (chainId) {
    switch (chainId) {
      case ChainId.ETHEREUM:
        address = '0xD132Ce8eA8865348Ac25E416d95ab1Ba84D216AF'
        break
      case ChainId.GÖRLI:
        address = '0xC2cFBcd43cEc9B9660A92B6C1cFafA0AA2Bc627C'
        break
    }
  }
  return useContract(address, DASHBOARD_ABI, false)
}

export function useQuickSwapFactoryContract(): Contract | null {
  return useContract('0x5757371414417b8C6CAad45bAeF941aBc7d3Ab32', FACTORY_ABI, false)
}
