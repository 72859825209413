const SEO = {
  defaultTitle: 'Nevellus',
  titleTemplate: 'Nevellus | %s',
  description: 'Make trades, provide liquidity or deep dive on Analytics of Nevellus DEX, launch all in one community driven ecosystem',
  openGraph: {
    type: 'website',
    url: 'https://app.nevellus.com/',
    site_name: 'Nevellus App',
  },
  twitter: {
    handle: '@NevellusDex',
    site: '@NevellusDex',
    cardType: 'summary_large_image',
  },
}

export default SEO
