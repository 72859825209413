import { ChainId } from '@nevellusdex/core-sdk'

const Goerli = '/images/networks/goerli-network.jpg'
const Mainnet = '/images/networks/mainnet-network.jpg'
const Polygon = '/images/networks/polygon-network.jpg'
const Matic = '/images/networks/matic-network.jpg'

export const NETWORK_ICON: Record<number, string> = {
  [ChainId.ETHEREUM]: Mainnet,
  [ChainId.GÖRLI]: Goerli,
  // [ChainId.KOVAN]: Kovan,
  // [ChainId.FANTOM]: Fantom,
  // [ChainId.FANTOM_TESTNET]: Fantom,
  // [ChainId.BSC]: Bsc,
  // [ChainId.BSC_TESTNET]: Bsc,
  [ChainId.MATIC]: Polygon,
  [ChainId.MATIC_TESTNET]: Matic,
  // [ChainId.XDAI]: xDai,
  // [ChainId.ARBITRUM]: Arbitrum,
  // [ChainId.ARBITRUM_TESTNET]: Arbitrum,
  // [ChainId.MOONBEAM_TESTNET]: Moonbeam,
  // [ChainId.AVALANCHE]: Avalanche,
  // [ChainId.AVALANCHE_TESTNET]: Avalanche,
  // [ChainId.HECO]: Heco,
  // [ChainId.HECO_TESTNET]: Heco,
  // [ChainId.HARMONY]: Harmony,
  // [ChainId.HARMONY_TESTNET]: Harmony,
  // [ChainId.OKEX]: OKEx,
  // [ChainId.OKEX_TESTNET]: OKEx,
  // [ChainId.CELO]: Celo,
  // [ChainId.PALM]: Palm,
  // [ChainId.MOONRIVER]: Moonriver,
  // [ChainId.FUSE]: Fuse,
  // [ChainId.TELOS]: Telos,
  // [ChainId.MOONBEAM]: Moonbeam,
  // [ChainId.OPTIMISM]: Optimism,
  // [ChainId.KAVA]: Kava,
  // [ChainId.METIS]: Metis,
  // [ChainId.ARBITRUM_NOVA]: ArbitrumNova,
  // [ChainId.BOBA_AVAX]: BobaAvax,
}

export const NETWORK_LABEL: Record<number, string> = {
  [ChainId.ETHEREUM]: 'Ethereum',
  [ChainId.GÖRLI]: 'Görli Testnet',
  // [ChainId.KOVAN]: 'Kovan',
  // [ChainId.FANTOM]: 'Fantom',
  // [ChainId.FANTOM_TESTNET]: 'Fantom Testnet',
  [ChainId.MATIC]: 'Polygon',
  [ChainId.MATIC_TESTNET]: 'Polygon Testnet',
  // [ChainId.XDAI]: 'Gnosis',
  // [ChainId.ARBITRUM]: 'Arbitrum',
  // [ChainId.ARBITRUM_TESTNET]: 'Arbitrum Testnet',
  // [ChainId.BSC]: 'BSC',
  // [ChainId.BSC_TESTNET]: 'BSC Testnet',
  // [ChainId.MOONBEAM_TESTNET]: 'Moonbase',
  // [ChainId.AVALANCHE]: 'Avalanche',
  // [ChainId.AVALANCHE_TESTNET]: 'Fuji',
  // [ChainId.HECO]: 'HECO',
  // [ChainId.HECO_TESTNET]: 'HECO Testnet',
  // [ChainId.HARMONY]: 'Harmony',
  // [ChainId.HARMONY_TESTNET]: 'Harmony Testnet',
  // [ChainId.OKEX]: 'OKEx',
  // [ChainId.OKEX_TESTNET]: 'OKEx',
  // [ChainId.CELO]: 'Celo',
  // [ChainId.PALM]: 'Palm',
  // [ChainId.MOONRIVER]: 'Moonriver',
  // [ChainId.FUSE]: 'Fuse',
  // [ChainId.TELOS]: 'Telos EVM',
  // [ChainId.MOONBEAM]: 'Moonbeam',
  // [ChainId.OPTIMISM]: 'Optimism',
  // [ChainId.KAVA]: 'Kava',
  // [ChainId.METIS]: 'Metis',
  // [ChainId.ARBITRUM_NOVA]: 'Arbitrum Nova',
  // [ChainId.BOBA_AVAX]: 'Boba Avax',
}
