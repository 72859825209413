import { ChainId } from '@nevellusdex/core-sdk'

const RPC = {
  [ChainId.ETHEREUM]: 'https://eth-mainnet.g.alchemy.com/v2/qtOKW5wSO_2yDC_dewwUYJkHMe9rekPe',
  [ChainId.GÖRLI]: 'https://eth-goerli.g.alchemy.com/v2/nIzRm5GMwiHtP__6JV864FFp_Prm1CfO',
  // [ChainId.KOVAN]: 'https://eth-kovan.alchemyapi.io/v2/wnW2uNdwqMPes-BCf9lTWb9UHL9QP2dp',
  // [ChainId.FANTOM]: 'https://rpcapi.fantom.network',
  // [ChainId.FANTOM_TESTNET]: 'https://rpc.testnet.fantom.network',
  // [ChainId.MATIC]: 'https://polygon-rpc.com/',
  [ChainId.MATIC_TESTNET]: 'https://polygon-mumbai.g.alchemy.com/v2/agtxP5l_CX0HbFuOzAa1mffcij4id1nu',
  // [ChainId.XDAI]: 'https://rpc.gnosischain.com',
  // [ChainId.BSC]: 'https://bsc-dataseed.binance.org/',
  // [ChainId.BSC_TESTNET]: 'https://data-seed-prebsc-2-s3.binance.org:8545',
  // [ChainId.MOONBEAM_TESTNET]: 'https://rpc.testnet.moonbeam.network',
  // [ChainId.AVALANCHE]: 'https://api.avax.network/ext/bc/C/rpc',
  // [ChainId.AVALANCHE_TESTNET]: 'https://api.avax-test.network/ext/bc/C/rpc',
  // [ChainId.HECO]: 'https://http-mainnet.hecochain.com',
  // [ChainId.HECO_TESTNET]: 'https://http-testnet.hecochain.com',
  // [ChainId.HARMONY]: 'https://api.harmony.one',
  // [ChainId.HARMONY_TESTNET]: 'https://api.s0.b.hmny.io',
  // [ChainId.OKEX]: 'https://exchainrpc.okex.org',
  // [ChainId.OKEX_TESTNET]: 'https://exchaintestrpc.okex.org',
  // [ChainId.ARBITRUM]: 'https://arb1.arbitrum.io/rpc',
  // [ChainId.PALM]: 'https://palm-mainnet.infura.io/v3/da5fbfafcca14b109e2665290681e267',
  // [ChainId.FUSE]: 'https://rpc.fuse.io',
  // [ChainId.CELO]: 'https://forno.celo.org',
  // [ChainId.MOONRIVER]: 'https://rpc.moonriver.moonbeam.network',
  // [ChainId.TELOS]: 'https://mainnet.telos.net/evm',
  // [ChainId.MOONBEAM]: 'https://rpc.api.moonbeam.network',
  // [ChainId.OPTIMISM]: 'https://mainnet.optimism.io',
  // [ChainId.KAVA]: 'https://evm.kava.io',
  // [ChainId.METIS]: 'https://andromeda.metis.io/?owner=1088',
  // [ChainId.ARBITRUM_NOVA]: 'https://a4ba.arbitrum.io/rpc',
  // [ChainId.BOBA_AVAX]: 'https://avax.boba.network',
}

export default RPC
