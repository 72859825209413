export enum Feature {
  AMM = 'AMM',
  LIQUIDITY_MINING = 'Liquidity Mining',
  ANALYTICS = 'Analytics',
  MIGRATE = 'Migrate',
  STAKING = 'Staking',
  LIMIT_ORDERS = 'LimitOrders',
  NEVELLUSGUARD = 'Nevellus Guard',
  SUBGRAPH = 'Subgraph',
  VAULT = 'Nevellus Vault'
}
